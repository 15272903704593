.container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f4f4f4; /* Light background for the section */
    margin-top: 20px;
}

.educationSection, .languagesSection {
    flex: 1;
    margin: 10px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.educationItem, .languageItem {
    margin-bottom: 15px;
}

h2 {
    color: #333;
    margin-bottom: 10px;
}

h3 {
    font-size: 1.1rem;
    color: #555;
}

p {
    font-size: 0.9rem;
    color: #666;
}
