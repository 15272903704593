.faqContainer {
    width: 80%;
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border-radius: 8px;
    text-align: center;
}

h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.faqList {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faqItem {
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
}

button {
    width: 100%;
    background-color: #0077cc;
    color: white;
    border: none;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s;
}

button:hover {
    background-color: #005fa3;
}

.videoContainer {
    margin-top: 10px;
}

video {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}
