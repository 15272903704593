.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.videoContainer {
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
}

.video {
    width: 100%;
    height: auto;
}

.summary {
    text-align: center;
}

.home {
    background-color: #f0f0f0; /* Light grey to see the section */
}