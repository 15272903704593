.skillsContainer {
    width: 80%;
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border-radius: 8px;
    text-align: center;
}

h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.skillsGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.skillCategory {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    width: 250px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: left;
}

h3 {
    font-size: 1.2rem;
    color: #0077cc;
    margin-bottom: 10px;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    font-size: 1rem;
    color: #555;
    padding: 5px 0;
}
