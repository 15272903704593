.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #1a1a2e;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.logo {
    font-size: 1.8rem;
    font-weight: bold;
}

.nav {
    display: flex;
    gap: 25px;
}

.nav a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    transition: color 0.3s ease;
}

.nav a:hover {
    color: #0077cc;
}

.contactButton {
    background-color: #0077cc;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.contactButton:hover {
    background-color: #005fa3;
}
