.timeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    max-width: 960px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9; /* Light background for the timeline */
    box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.timelineItem {
    border-left: 4px solid #0077cc; /* A nice blue for contrast */
    padding: 10px 20px;
    margin: 10px 0;
    position: relative;
}

.timelineItem:not(:last-child)::after {
    content: '';
    position: absolute;
    left: -2px; /* Align with the border */
    top: 0;
    bottom: -20px;
    width: 1px;
    background-color: #0077cc; /* Same as the border */
}

.years {
    font-weight: bold;
    color: #555;
    margin-bottom: 5px;
}

.logo {
    width: 100px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

h3 {
    font-size: 1.25rem;
    color: #333;
    margin-top: 0;
}

p {
    font-size: 1rem;
    color: #666;
}

a {
    color: #0077cc;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
