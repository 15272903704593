.footer {
    background-color: #1a1a2e;
    color: white;
    text-align: center;
    padding: 20px 0;
    font-size: 1rem;
}

.footerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.footerLinks {
    display: flex;
    gap: 20px;
}

.footerLinks a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.footerLinks a:hover {
    color: #0077cc;
}

.footerBottom {
    margin-top: 10px;
    font-size: 0.9rem;
    opacity: 0.7;
}
