.certificationsContainer {
    width: 80%;
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border-radius: 8px;
    text-align: center;
}

h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.certificationsList {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.certificationItem {
    background-color: #f9f9f9;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

h3 {
    font-size: 1.2rem;
    color: #0077cc;
    margin-bottom: 5px;
}

p {
    font-size: 1rem;
    color: #555;
}

a {
    display: inline-block;
    margin-top: 10px;
    color: #0077cc;
    text-decoration: none;
    font-weight: bold;
}

a:hover {
    text-decoration: underline;
}
