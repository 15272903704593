.chatButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #0077cc;
    color: white;
    padding: 15px 20px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
}

.chatOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatboxContainer {
    background: #1a1a2e;
    width: 500px;
    border-radius: 15px;
    color: white;
}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.closeButton {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
}
